@font-face {
	font-family: 'Poppins';
	src: url('./fonts/Poppins-Regular.woff2') format('woff2'),
		url('./fonts/Poppins-Regular.woff') format('woff'),
		url('./fonts/Poppins-Regular.ttf')  format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('./fonts/Poppins-Medium.woff2') format('woff2'),
		url('./fonts/Poppins-Medium.woff') format('woff'),
		url('./fonts/Poppins-Medium.ttf')  format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins';
	src: url('./fonts/Poppins-Light.woff2') format('woff2'),
		url('./fonts/Poppins-Light.woff') format('woff'),
		url('./fonts/Poppins-Light.ttf')  format('truetype');
	font-weight: 300;
	font-style: normal;
}


body {
	background: #F1F6FF;
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-size: 15px;
	color: #212529;
}
a { color: #5985DE; }

.container {
	display: block;
	width: 80%;
	margin: 0 auto;
	padding: 2rem;
}

header h1 {
	font-weight: normal;
	font-size: 2.25rem;
	margin-top: 2.25rem;
}
button, input[type="submit"] {
	border-radius: 0.25rem;
	border: 0;
	background: #5985DE;
	color: #ffffff;
	font-weight: normal;
	font-size: 1rem;
	padding: 0.82rem 1.25rem;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
}
button:hover, input[type="submit"]:hover { background: #23376C; }
button:focus, input:focus { outline: none; }
button.withIcon {
	background-color: #ffffff;
	border: 2px solid #5985DE;
	fill: #5985de;
	color: #5985DE;
	vertical-align: middle;
	padding: 0.65rem 1.25rem;
	display: flex;
	align-items: center;
}
button.withIcon:hover { 
	background-color: #5985DE;
	color: #ffffff;
	fill: #ffffff;
}
button.withIcon svg { margin-right: 0.25rem; }
button:disabled { opacity: 0.5; cursor: default;}
button:hover:disabled { background: #5985DE; }
input[type="text"], select {
	border: 1px solid #CACACA;
	border-radius: 0.25rem;
	font-size: 1rem;
	font-weight: 300;
	padding: 0.8rem;
	margin-right: 0.75rem;
	min-width: 33%;
}
input[type="radio"] { 
	position: relative;
	margin: 0.5rem 0.25rem;
}
input[type='radio']:after {
	width: 14px;
	height: 14px;
	border-radius: 14px;
	top: -2px;
	left: -1px;
	position: relative;
	background-color: #ffff;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid #CACACA;
}

input[type='radio']:checked:after {
	width: 14px;
	height: 14px;
	border-radius: 14px;
	top: -2px;
	left: -1px;
	position: relative;
	background-color: #ffffff;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid #CACACA;
}
input[type="radio"]:checked:before {
	background: #5985DE;
	width: 8px;
	height: 8px;
	border-radius: 8px;
	top: 3px;
	left: 4px;
	position: absolute;
	z-index: 2;
	content: '';
}
.errorMessage {
	background: red;
	position: absolute;
	font-size: 0.7rem;
	color: #fff;
	text-transform: uppercase;
	padding: 0rem 0.2rem;
	border-radius: 0.2rem;
}

.accordion .a-container {
	background: rgba(255,255,255,0.85);
	box-shadow: 0 3px 12px 0 rgba(0,0,0,0.05);
	margin-bottom: 1.25rem;
	border-radius: 0.75rem;
	padding: 1.25rem;
}
.accordion .a-container .a-btn {
	margin: 0;
	font-weight: 500;
	font-size: 1.25rem;
	opacity: 0.6;
	display: flex;
	flex-flow: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
}
.accordion .a-container .a-btn span { display: none; }
.accordion .a-container .a-panel {
	transition: all 0.2s ease-in-out;
	opacity: 0;
	height: auto;
	overflow: hidden;
	display: none;
}
.accordion .a-container.active {
	background: #ffffff;
	border: 2px solid #5985DE;
}
.accordion .a-container.active .a-btn {
	opacity: 1;
}
.accordion .a-container.active .a-panel {
	opacity: 1;
	height: auto;
	display: block;
}
.accordion .a-container.active .a-panel p {
	margin-top: 0;
}
.a-panel .form-group {
	margin: 1rem 0 0.5rem 0;
	list-style: none;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
}
.form-group.sender div {
	width: 36%;
	max-width: 50%;
	margin-right: 0.75rem;
	position: relative;
}
.form-group.sender div input, .form-group.sender div select { 
	width: calc(100% - 1.6rem);
	margin: 0;
}
.form-group.sender div .errorMessage { 
	position: absolute;
	right: -0.85rem;
	text-align: right;
	display: inline;
	min-width: auto;
	width: auto;
	max-width: 100%;
}

.accordion .a-container.done {
	background: #5985DE;
	color: #ffffff;
}
.accordion .a-container.done .a-btn { opacity: 1; }
.accordion .a-container.done .a-btn span {
	display: flex;
	width: 1.5rem;
	height: 1.5rem;
	background: url('./images/icon-edit.svg') no-repeat center center;
	background-size: 1.5rem;
}
.accordion .a-container.done .a-btn span.hide { display: none; }

ul.products {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	column-gap: 1rem;
	align-items: start;
}
ul.products li {
	text-align: center;
}
ul.products li .photo {
	min-width: 180px;
	min-height: 180px;
	border: 1px solid #CACACA;
	border-radius: 1.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0.5rem;
}
ul.products li .photo img {
	max-width: 120px;
	max-height: 120px;
}
ul.products li label {
	padding: 0 0 1rem 0;
	display: flex;
	flex-flow: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}
ul.products li label.selected .photo { border: 1px solid #5985DE; }

.a-panel .form-group.reasons {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	margin: 0.75rem 0 1rem 0;
}
ul.reasons li { margin-right: 2rem; }
button[name="sender"] { margin-top: 1rem; }

.parcelLabel {
	border: 1px solid #CACACA;
	border-radius: 0.75rem;
	padding: 1.25rem;
	margin-bottom: 1.25rem;
}
.parcelLabel .top {
	display: flex;
}
.parcelLabel .top .company {
	width: 100%;
	height: 3rem;
	border-bottom: 1px solid #CACACA;
}
.parcelLabel .sender { margin: 1rem 0 3rem 0; }
.parcelLabel .invoicedata {
	display: flex;
	flex-flow: row;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
}
.parcelLabel .invoicedata h3 {
	margin-bottom: 0.5rem;
	width: 100%;
}
form input[type="submit"] { display: inline-block; }
.summary .buttons {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
}
.labelActions {
	width: 70%;
	display: flex;
	justify-content: flex-end;
}
.labelActions button { margin-left: 1rem; }



@media (min-width: 720px) {
	
}


@media (max-width: 1200px) {
	.parcelLabel { 
		font-size: 0.7rem;
		padding: 1rem;
		line-height: 1.2;
	}
	.parcelLabel .top .company { height: 2.5rem; }
	.parcelLabel .sender { margin: 0 0 1rem 0; }
}

@media (max-width: 1060px) {
	ul.products { grid-template-columns: repeat(3, 1fr); }
}

@media (max-width: 840px) {
	ul.products { grid-template-columns: repeat(2, 1fr); }
	ul.products li .photo {
		min-width: 160px;
		min-height: 160px;
	}
	ul.products li .photo img {
		max-width: 100px;
		max-height: 100px;
	}
	input[type="text"], select { 
		min-width: calc(100% - 1.8rem); 
		margin: 0.35rem 0;
	}
	.btn.step { margin-top: 0.85rem; }
	.summary .btn.step { margin-top: 0; }
	.a-panel .form-group { margin: 0; }
	.a-panel .form-group.products { margin-top: 1.25rem; }
	.a-panel .form-group.sender { margin-top: 0.5rem; }
	.a-panel .form-group.reasons { margin-bottom: 0.25rem; }
	ul.reasons li {
		width: 100%;
		margin-right: 0;
	}
	.parcelLabel { 
		font-size: 0.4rem;
		padding: 0.6rem;
		border-radius: 0.5rem;
	}
	.parcelLabel .top .company {
		width: calc(100% - 5rem);
		margin-right: 2rem;
		height: 1.5rem;
	}
	.parcelLabel .top .qrcode { width: 3rem; }
	.parcelLabel .top .qrcode img { width: 100%; }
	.summary .buttons { flex-wrap: wrap;}
	.labelActions {
		width: 100%;
		justify-content: flex-start;
		order: 1;
	}
	.labelActions button { margin: 0 1rem 0 0; }
	.summary .buttons input[type="submit"] { 
		order: 2;
		margin-top: 1rem;
	}
}



@media (max-width: 580px) {
	.accordion .a-container .a-btn { font-size: 1.15rem; }
	ul.products { grid-template-columns: 1fr; }
	ul.products li .photo { display: none; }
	ul.products li label, ul.reasons label {
		display: flex;
		flex-flow: row;
		justify-content: flex-start;
		align-items: flex-start;
		text-align: left;
		padding: 0.8rem 0;
		border: 1px solid #CACACA;
		border-radius: 0.75rem;
		margin-bottom: 0.75rem;
	}
	input[type="radio"] { margin: 0.3rem 0.75rem; }
	.parcelLabel { font-size: 0.25rem; }
	.parcelLabel .top .company {
		width: calc(100% - 3rem);
		margin-right: 1rem;
		height: 1rem;
	}
	.parcelLabel .top .qrcode { width: 2rem; }
	.parcelLabel .sender { margin-bottom: 0.75rem; }
	.parcelLabel .invoicedata h3 { margin-bottom: 0.25rem; }
	.labelActions button { 
		margin: 0 1rem 0 0;
		font-size: 0.9rem;
		padding: 0.5rem 0.75rem;
		width:100%;
	}
	button.withIcon svg {
		display: none;
	}
}

@media (max-width: 420px) { 
}
@media print {
	.doNotPrint { visibility: hidden;}
	header{ display: none; }
	.printThis { 
		visibility: visible!important;
		position: absolute; 
		top: 2cm; 
		left: 0cm;
		font-size: 14px;
	}
}